import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Buffer } from 'buffer';
import { useDispatch, useSelector } from 'react-redux';
import {
  getTrip,
  getLabels
} from '@/redux/printLabelsFc/printLabelsFc.actions';
import { BackButton, Loader, Modal } from '@/components';
import { resetLabelsFc } from '@/redux/printLabelsFc/printLabelsFc.slice';
import validCheck from '@/assets/valid_check.png';
import { getProductsList } from '@/redux/missingProducts/missingProducts.actions';
import { selectUser } from '@/redux/user/user.slice';
import { toggleModal } from '@/redux/modal/modal.slice';

function PrintLabelsFcTrip() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { tripId } = useParams();
  const {
    selectedTrip: trip,
    labels,
    labelsStatus,
    error: labelsError
  } = useSelector((state) => state.printLabelsFc);
  const { data } = useSelector((state) => state.missingProducts);

  const { hubId } = useSelector(selectUser);
  const [boxIndex, setBoxIndex] = useState(0);
  const [printedIndex, setPrintedIndex] = useState(0);
  const [currentBoxId, setCurrentBoxId] = useState(null);
  const [isPrinting, setIsPrinting] = useState(false);
  const [error, setError] = useState(null);
  const [lastBoxPrinted, setLastBoxPrinted] = useState(null);
  const [printerIp, setPrinterIp] = useState(null);
  const [tripPrinted, setTripPrinted] = useState(false);
  const [alreadyPrintedBox, setAlreadyPrintedBox] = useState(false);
  const [printIteration, setPrintIteration] = useState(5);
  const [isPrintingAll, setIsPrintingAll] = useState(false);
  const [confirmPrintAll, setConfirmPrintAll] = useState(false);
  const [pendingReprint, setPendingReprint] = useState(null);

  useEffect(() => {
    dispatch(getTrip(tripId));
    dispatch(getProductsList(hubId));
  }, []);

  useEffect(() => {
    if (data.lines !== undefined) {
      setPrinterIp(data?.lines[0]?.printer?.addr);
    }
  }, [data]);

  const triggerSendToPrinter = async (
    barcode,
    id,
    index,
    forceReprint = false
  ) => {
    const nextBoxes = trip.boxes.slice(boxIndex, boxIndex + 5);

    if (
      (!forceReprint &&
        hubId === 6 &&
        nextBoxes.find((box) => box.printStatus === 'PRINTED')) ||
      (!forceReprint && trip.boxes[boxIndex].printStatus === 'PRINTED')
    ) {
      setAlreadyPrintedBox(true);
      setPendingReprint({ barcode, id, index });
      dispatch(toggleModal(true));
      return;
    }

    await dispatch(getLabels({ barCode: barcode, boxId: id }));
    setCurrentBoxId(id);
    setPrintIteration((p) => p - 1);
    setPrintedIndex(index + 1);
    if (index + 1 === trip?.boxes?.length) {
      setTripPrinted(true);
      setTimeout(() => {
        setTripPrinted(false);
      }, 10000);
    }
  };

  const handlePrint = async () => {
    setIsPrinting(true);

    if (!printerIp) {
      dispatch(toggleModal(true));
      setIsPrinting(false);
      return;
    }

    const boxBeingPrintedId = trip.boxes[boxIndex].id;
    setCurrentBoxId(boxBeingPrintedId);

    if (boxBeingPrintedId && labels) {
      const tmpLabelArray = [labels.box, ...labels.packages];

      const resolvedLabels = tmpLabelArray.map((label) =>
        Buffer.from(label, 'base64').toString('utf-8')
      );

      if (window.printLabelFc) {
        for (let i = 0; i < resolvedLabels.length; i += 1) {
          const encodedData = Buffer.from(
            JSON.stringify({ zplTemplate: resolvedLabels[i], printerIp })
          ).toString('base64');
          window.printLabelFc.postMessage(encodedData);
          // eslint-disable-next-line no-promise-executor-return
          await new Promise((resolve) => setTimeout(resolve, 3000));
        }
        setLastBoxPrinted(boxBeingPrintedId);
      }

      setIsPrinting(false);
      if (boxIndex < trip.boxes.length - 1) {
        setBoxIndex((p) => p + 1);
        setPrintedIndex((prevPrintedIndex) => prevPrintedIndex + 1);
        if (printIteration > 1 && hubId === 6) {
          triggerSendToPrinter(
            trip.boxes[boxIndex + 1].barCode,
            trip.boxes[boxIndex + 1].id,
            boxIndex + 1
          );
        } else {
          setPrintIteration(5);
          setPrintedIndex(boxIndex + 1);
        }
      }
    }
  };

  const handlePrintAll = async () => {
    setIsPrintingAll(true);
    const labelPromises = trip?.boxes?.map(async (box) => {
      const result = await dispatch(
        getLabels({ barCode: box.barCode, boxId: box.id })
      );
      if (result.payload) {
        const boxLabels = result.payload.box;
        const packageLabels = result.payload.packages || [];
        return [boxLabels, ...packageLabels];
      }
      return [];
    });

    const resolvedLabels = await Promise.all(labelPromises);
    const allResolvedLabels = resolvedLabels.flat();

    if (window.printLabelFc) {
      for (let i = 0; i < allResolvedLabels.length; i += 1) {
        const encodedData = Buffer.from(
          JSON.stringify({ zplTemplate: allResolvedLabels[i], printerIp })
        ).toString('base64');
        window.printLabelFc.postMessage(encodedData);
        // eslint-disable-next-line no-promise-executor-return
        await new Promise((resolve) => setTimeout(resolve, 3000));
      }
    }

    setPrintedIndex(trip?.boxes?.length);
    setBoxIndex((trip?.boxes?.length ?? 1) - 1);
    setTripPrinted(true);
    setIsPrintingAll(false);
  };

  const confirmReprint = async () => {
    if (pendingReprint) {
      const { barcode, id, index } = pendingReprint;
      await triggerSendToPrinter(barcode, id, index, true);
      setPendingReprint(null);
    }
    dispatch(toggleModal(false));
  };

  const handleCancel = () => {
    setPendingReprint(null);
    dispatch(toggleModal(false));
    setAlreadyPrintedBox(null);
  };

  const resolveModalContent = () => {
    if (confirmPrintAll) {
      return (
        <div className="picking-modal-container">
          <h1>{t('views.print_labels_trip.print_all_confirmation')}</h1>
          <div className="picking-modal-container__buttons">
            <div
              className="picking-modal-container__buttons__cancel"
              type="button"
              onClick={() => {
                setConfirmPrintAll(false);
                dispatch(toggleModal(false));
              }}
            >
              {t('views.print_labels_trip.no')}
            </div>

            <div
              className="picking-modal-container__buttons__confirm"
              type="button"
              onClick={() => {
                handlePrintAll();
                setConfirmPrintAll(false);
                dispatch(toggleModal(false));
              }}
            >
              {t('views.print_labels_trip.yes')}
            </div>
          </div>
        </div>
      );
    }

    if (alreadyPrintedBox) {
      const boxes =
        hubId === 6
          ? trip.boxes.slice(boxIndex, boxIndex + 5)
          : trip.boxes[boxIndex];
      return (
        <div className="already-printed-wrapper">
          <h2>{t('views.print_labels_trip.already_printed_boxes')}</h2>
          <ul>
            {hubId === 6 ? (
              boxes.map((box) => (
                <li key={box.id}>
                  {box.id}{' '}
                  {box.printStatus === 'PRINTED'
                    ? t('views.print_labels_trip.already_printed')
                    : ''}
                </li>
              ))
            ) : (
              <li key={boxes.id}>
                {boxes.id}{' '}
                {boxes.printStatus === 'PRINTED'
                  ? t('views.print_labels_trip.already_printed')
                  : ''}
              </li>
            )}
          </ul>
          <div className="already-printed-wrapper__buttons">
            <div
              className="already-printed-wrapper__buttons__reprint"
              onClick={confirmReprint}
            >
              {t('views.print_labels_trip.reprint')}
            </div>
            <div
              className="already-printed-wrapper__buttons__cancel"
              onClick={handleCancel}
            >
              {t('views.print_labels_trip.cancel')}
            </div>
          </div>
        </div>
      );
    }

    return <h1>{t('views.print_labels_trip.no_printer_ip')}</h1>;
  };

  const handleBackClick = () => {
    if (hubId !== 6 && boxIndex !== 0) {
      setBoxIndex(boxIndex - 1);
    } else if (hubId === 6 && boxIndex > 5) setBoxIndex(boxIndex - 5);
    else setBoxIndex(0);
  };

  const forwardClick = () => {
    if (hubId !== 6 && boxIndex !== trip.boxes.length - 1)
      setBoxIndex(boxIndex + 1);
    else if (hubId === 6 && boxIndex + 5 < trip.boxes.length - 1) {
      setBoxIndex(boxIndex + 5);
    }

    return null;
  };

  useEffect(() => {
    if (labelsStatus === 'success') {
      if (currentBoxId && !isPrinting) {
        handlePrint();
      }
    } else if (labelsStatus === 'failed') {
      setError(labelsError);
      setLastBoxPrinted(null);
      setTimeout(() => {
        setError(null);
        dispatch(resetLabelsFc());
        dispatch(getTrip(tripId));
      }, 3000);
    }
  }, [labels, currentBoxId]);

  return trip?.boxes?.length ? (
    <div className="trip-fc-wrapper">
      <div style={{ position: 'absolute', top: '1em', left: '1em' }}>
        <BackButton />
      </div>
      <div className="trip-fc-wrapper__header">
        {trip?.label} ({trip?.boxes?.length} boxes)
      </div>
      <div className="print-area-wrapper">
        {error && (
          <h1 style={{ textAlign: 'center', fontSize: '1em' }}>{error}</h1>
        )}
        <span>{t('views.print_labels_trip.box_label')}</span>
        <div className="print-area-wrapper__box-infos">
          <div className="print-area-wrapper__box-infos__counter-wrapper">
            {boxIndex + 1}/{trip?.boxes?.length}
          </div>
          BB {trip?.boxes[boxIndex]?.id}
        </div>
        <div className="print-area-wrapper__navigation-buttons">
          <div
            className={`${boxIndex === 0 ? 'disabled' : ''} fc-button`}
            onClick={() => handleBackClick()}
          >
            {'<'}
          </div>
          {isPrinting || isPrintingAll ? (
            <Loader />
          ) : (
            <div
              className="print fc-button"
              onClick={() =>
                triggerSendToPrinter(
                  trip.boxes[boxIndex].barCode,
                  trip.boxes[boxIndex].id,
                  boxIndex
                )
              }
            >
              {t('views.print_labels_trip.print')}
            </div>
          )}
          <div
            className={`${
              boxIndex === trip.boxes.length - 1 ? 'disabled' : ''
            } fc-button`}
            onClick={() => forwardClick()}
          >
            {'>'}
          </div>
        </div>
        <div className="print-area-wrapper__print-all">
          <button
            type="button"
            className="print-all fc-button"
            onClick={() => {
              setConfirmPrintAll(true);
              dispatch(toggleModal(true));
            }}
            disabled={isPrintingAll || isPrinting}
          >
            {isPrintingAll || isPrinting ? (
              <Loader />
            ) : (
              t('views.print_labels_trip.print_all')
            )}
          </button>
        </div>
        {lastBoxPrinted && (
          <div className="print-area-wrapper__printed-tracker">
            <div className="print-area-wrapper__printed-tracker__bold">
              {printedIndex}/{trip?.boxes?.length}
            </div>
            BB {lastBoxPrinted} {t('views.print_labels_trip.printed')}{' '}
            <img src={validCheck} alt="checkmark" />
          </div>
        )}
        {tripPrinted && (
          <div style={{ marginTop: '1em', fontSize: '2em', fontWeight: '500' }}>
            {t('views.print_labels_trip.trip')} {trip?.label}{' '}
            {t('views.print_labels_trip.done')}
          </div>
        )}
      </div>
      <Modal onClose={handleCancel}>{resolveModalContent()}</Modal>
    </div>
  ) : (
    <div className="loader-wrapper">
      <Loader />
    </div>
  );
}

export default PrintLabelsFcTrip;
